import styled from '@emotion/styled'

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 6em;

  @media (min-width: 1025px) {
    display: none;
  }
`

export const ContainerEmpty = styled.div`
  width: calc(100% / 3);
`

export const ContainerLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% / 3);
  z-index: 30;
`

export const ContainerCart = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: calc(100% / 3);
  img {
    margin-right: 0.8em;
  }
`

export const MobileCartLink = styled.div`
  margin: auto !important;
`
