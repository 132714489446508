import { css, Global } from '@emotion/core'
import styled from '@emotion/styled'
import Image from 'gatsby-image'
import React from 'react'
import avenirBlack from '../data/fonts/Avenir Black.ttf'
import avenirLight from '../data/fonts/avenirltstd-light.otf'
import futuristFixed from '../data/fonts/futurist.ttf'
import AvenirBold from '../data/fonts/Metropolis-SemiBold.otf'
import AvenirMega from '../data/fonts/Metropolis-Bold.otf'

export const breakpoints = {
  s: 576,
  m: 813,
  l: 1023,
  xl: 1200
}

export const GlobalStyle = props => (
  <Global
    {...props}
    styles={css`
      @font-face {
        font-family: 'Avenir Light';
        src: url(${avenirLight}) format('truetype');
      }
      @font-face {
        font-family: 'Futurist Fixed';
        src: url(${futuristFixed}) format('truetype');
      }
      @font-face {
        font-family: 'Avenir Black';
        src: url(${avenirBlack}) format('truetype');
      }
      @font-face {
        font-family: 'Avenir Bold';
        src: url(${AvenirBold}) format('truetype');
      }
      @font-face {
        font-family: 'Avenir Mega';
        src: url(${AvenirMega}) format('truetype');
      }
      body {
        margin: 0;
      }
      html {
        font-family: 'Krona One', sans-serif;
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
        scroll-behavior: smooth;
        max-width: 1600px;
        margin: 0 auto;
      }
      
        // FIX AOS WHITE SPACE
      html,
      body {
        display: block;
        padding: 0px;
        overflow-x: hidden;
        width: 100%;
      }

      button {
        outline: none;
      }

      .bm-menu {
        background: white;
        padding: 2.5em 1.5em 0;
        font-size: 1.15em;
      }

      @media (max-width: 1025px) {
        .bm-burger-button {
          position: absolute;
          top: 0;
          left: 0;
          width: 40px;
          margin: 1.9em 0 0 0.8em;
        }
      }

      @media (min-width: 1025px) {
        .bm-burger-button {
          display: none;
        }
      }

      .bm-burger-bars {
        display: absolute;
        height: 6em !important;
      }
      
      .page-legale {
        max-width: 1000px;
        margin: 0 auto;
      }
      
      @media (max-width: 1200px) {
          .page-legale {
            max-width: 80%;
        }
      }

        @media (max-width: 576px) {
            .page-legale {
              max-width: 90%;
          }
        }
      
      .page-legale p, li, table {
        font-family: 'Avenir Light', serif;
        color: #4D4D4D;
      }
      
      .page-legale h3 {
        color: #2B5C6B;
        font-size: 1.2em;
        margin: 4em 0 2em;
      }
      
      .page-legale h4 {
        color: #2B5C6B;
        font-size: 1.1em;
      }
      
      .page-legale h5, h6 {
        color: #2B5C6B;
        font-size: 1em;
      }
    `}
  />
)

export const Img = styled(Image)`
  max-width: 100%;
  padding: 0;
  margin: 0 0 1.45rem;
`

export const Container = styled.div`
  margin: 0 auto;
  max-width: 960px;
`

export const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2rem 1fr;
  grid-template-rows: auto;
  grid-template-areas: 'left . right';

  @media (max-width: ${breakpoints.l}px) {
    display: block;
  }
`

export const GridLeft = styled.div`
  grid-area: left;
`

export const GridRight = styled.div`
  grid-area: right;
`

export const MainContent = styled.main`
  margin-top: 80px;
  margin-bottom: 40px;

  @media (max-width: ${breakpoints.l}px) {
    margin-top: 40px;
    margin-bottom: 20px;
  }
`
