import React from 'react'
import Img from 'gatsby-image'

import { Header, ContainerCart, ContainerLogo, ContainerEmpty } from './styles.js'

import { CartCounter, CartLink } from '../styles.js'
import Cart from '../Cart'
import { graphql, Link, useStaticQuery } from 'gatsby'

const MobileHeader = () => {
  const logo = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/logo.png" }) {
        childImageSharp {
          fixed(width: 140) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <>
      <Header>
        <ContainerEmpty />
        <ContainerLogo>
          <Link to='/'>
            <Img fixed={logo.file.childImageSharp.fixed} alt='jh&co' />
          </Link>
        </ContainerLogo>
        <ContainerCart>
          <Cart CartCounter={CartCounter} CartLink={CartLink} />
        </ContainerCart>
      </Header>
    </>
  )
}

export default MobileHeader
