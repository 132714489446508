import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import ContextProvider from '~/provider/ContextProvider'

import { GlobalStyle } from '~/utils/styles'
import Navigation from '~/components/Navigation'
import Footer from '~/components/Footer'
import MobileHeader from '../components/Navigation/MobileNavigation'
import BurgerMenu from '../components/Navigation/BurgerMenu'
import PushUp from '../components/PushUp'

const Layout = ({ children }) => {
  return (
    <ContextProvider>
      <GlobalStyle />
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <div id='app'>
            <BurgerMenu />
            <MobileHeader />
            <Navigation siteTitle={data.site.siteMetadata.title} />
            {children}
            <PushUp />
            <Footer />
          </div>
        )}
      />
    </ContextProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
