import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Cart from './Cart'
import {
  CartCounter,
  CartLink,
  CollectButton,
  DropDown,
  DropDownContent,
  DropDownTitle, Header,
  Logo,
  MenuLink,
  NavContainer, Separator
} from './styles'

const Navigation = () => {
  const [showEshopMenu, setSowEshopMenu] = useState(false)

  const logo = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/logo.png" }) {
        childImageSharp {
          fixed(width: 140) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <>
      <Header>
        <Logo to='/'>
          <Img fixed={logo.file.childImageSharp.fixed} alt='jh&co' />
        </Logo>

        <NavContainer>
          <CollectButton href='https://jhnco-clickncollect.fr'>
            Click’n’Collect
          </CollectButton>
          <MenuLink to='/'>Nous connaitre</MenuLink>
          <MenuLink to='/cote-pro'>Coté Pro</MenuLink>
          <MenuLink to='/developpement-durable'>Developpement durable</MenuLink>
          <DropDown
            onMouseOver={() => setSowEshopMenu(true)}
            onMouseLeave={() => setSowEshopMenu(false)}
          >
            <DropDownTitle>E-shop</DropDownTitle>
            {showEshopMenu &&
              <DropDownContent>
                <MenuLink to='/e-shop' replace state={{ collection: 'Nos cafés' }} style={{ marginBottom: '0.5em' }}>Nos cafés</MenuLink>
                <Separator />
                <MenuLink to='/e-shop/' replace state={{ collection: 'S\'équiper Jh' }}>S'équiper Jh</MenuLink>
              </DropDownContent>}
          </DropDown>
          <MenuLink to='/contact'>contact</MenuLink>
          <Cart CartCounter={CartCounter} CartLink={CartLink} />
        </NavContainer>
      </Header>
    </>
  )
}

Navigation.propTypes = {
  siteTitle: PropTypes.string
}

Navigation.defaultProps = {
  siteTitle: ''
}

export default Navigation
