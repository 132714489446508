import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { breakpoints } from '../../utils/styles'


export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 6em;
  padding: 0 0 0 4em;

  @media (max-width: ${breakpoints.l}px) {
    justify-content: space-between;
  }

  @media (max-width: 1025px) {
    display: none;
  }
`

export const Logo = styled(Link)`
  margin-top: 0.6em;
`

export const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.45rem;
  width: 70%;
  max-width: 1200px;
  z-index: 30;

  @media (max-width: ${breakpoints.l}px) {
    display: none;
  }
`

export const DropDownContent = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  height: auto;
  padding: 1em;
  width: 8em;
  left: -2.5em;
  right: 0;
  margin: 0 auto;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  
  a {
    font-size: 1em;
    font-family: 'Avenir Light';
  }
`

export const DropDown = styled.div`
  position: relative;
  display: inline-block;
`

export const DropDownTitle = styled.p`
  color: #000000;
  text-decoration: none;
  font-size: 0.8em;
  cursor: pointer;
`

export const Separator = styled.div`
  border-top: 1px solid black;
  width: 3em;
  margin: 0 auto;
  margin-bottom: .5em;
`

export const MenuLink = styled(Link)`
  color: #000000;
  text-decoration: none;
  font-size: 0.8em;

  :hover {
    color: #2b5c6b;
  }

  @media (max-width: ${breakpoints.s}px) {
    font-size: 1.4rem;
  }
`

export const CartLink = styled(Link)`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  color: #000000;
  width: 3em;
  height: 3em;
  padding-bottom: 1em;

  @media (max-width: ${breakpoints.l}px) {
    padding-bottom: 0;
  }
`

export const CollectButton = styled.a`
  color: #eed7b8;
  text-decoration: none;
  font-size: 0.8em;
  padding: 0.4em 0.8em;
  background-color: #2b5c6b;
  border-radius: 20px;
  border: 1px solid transparent;

  @media (hover: hover) {
    :hover {
      background-color: white;
      color: #000;
      border: 1px solid #2b5c6b;
    }
  }

  @media (max-width: ${breakpoints.s}px) {
    font-size: 1.4rem;
  }
`

export const CartCounter = styled.span`
  background-color: #2b5c6b;
  position: absolute;
  color: white;
  border-radius: 20px;
  padding: 3px 6px;
  font-size: 0.8rem;
  z-index: 20;
  top: 0;
  right: 5px;
`
