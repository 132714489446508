// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-cgv-js": () => import("./../../../src/pages/cgv.js" /* webpackChunkName: "component---src-pages-cgv-js" */),
  "component---src-pages-condition-utilisation-js": () => import("./../../../src/pages/condition-utilisation.js" /* webpackChunkName: "component---src-pages-condition-utilisation-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cote-pro-js": () => import("./../../../src/pages/cote-pro.js" /* webpackChunkName: "component---src-pages-cote-pro-js" */),
  "component---src-pages-developpement-durable-js": () => import("./../../../src/pages/developpement-durable.js" /* webpackChunkName: "component---src-pages-developpement-durable-js" */),
  "component---src-pages-e-shop-js": () => import("./../../../src/pages/e-shop.js" /* webpackChunkName: "component---src-pages-e-shop-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentions-legales-js": () => import("./../../../src/pages/mentions-legales.js" /* webpackChunkName: "component---src-pages-mentions-legales-js" */),
  "component---src-pages-politique-de-confidentialite-js": () => import("./../../../src/pages/politique-de-confidentialite.js" /* webpackChunkName: "component---src-pages-politique-de-confidentialite-js" */),
  "component---src-pages-politique-de-remboursement-js": () => import("./../../../src/pages/politique-de-remboursement.js" /* webpackChunkName: "component---src-pages-politique-de-remboursement-js" */),
  "component---src-pages-politique-expedition-js": () => import("./../../../src/pages/politique-expedition.js" /* webpackChunkName: "component---src-pages-politique-expedition-js" */),
  "component---src-templates-product-page-index-js": () => import("./../../../src/templates/ProductPage/index.js" /* webpackChunkName: "component---src-templates-product-page-index-js" */)
}

