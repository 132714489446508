import React, { useState, useEffect } from 'react'
import { slide as Menu } from 'react-burger-menu'
import crossIcon from '../../../data/icons/cancel.png'
import menuIcon from '../../../data/icons/menu.png'
import { CollectButton, EshopMenu, EshopMenuTitle, LinkWrapper, MenuLink, MenuLinkShop } from './styles'

const BurgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false)

  const isMenuOpen = state => {
    console.log(state)
    return setIsOpen(state.isOpen)
  }

  const [showBurger, setShowBurger] = useState(false)

  const handleSetWith = () => {
    const width = window.innerWidth

    if (width <= 1025) setShowBurger(true)
    else setShowBurger(false)
  }

  useEffect(() => {
    if (window) {
      handleSetWith()
    }

    window.addEventListener('resize', handleSetWith)
    return () => window.addEventListener('resize', handleSetWith)
  }, [])

  return (
    <>
      {showBurger && <Menu
        id='burger-menu'
        left
        outerContainerId='app'
        customBurgerIcon={<img src={menuIcon} />}
        customCrossIcon={<img src={crossIcon} />}
        width='60%'
        disableAutoFocus
        onStateChange={isMenuOpen}
        isOpen={isOpen}
                     >
        {/* href='https://jh-co-clickandcollect.myshopify.com/collections/' */}
        <CollectButton href='https://jhnco-clickncollect.fr'>
          Click’n’Collect
        </CollectButton>

        <MenuLink onClick={() => setIsOpen(!isOpen)} className='menu-item' to='/'>
          Nous connaitre
        </MenuLink>

        <MenuLink
          onClick={() => setIsOpen(!isOpen)}
          className='menu-item'
          to='/cote-pro'
        >
          Coté Pro
        </MenuLink>

        <MenuLink
          onClick={() => setIsOpen(!isOpen)}
          className='menu-item'
          to='/developpement-durable'
        >
          Developpement durable
        </MenuLink>

        <EshopMenu>
          <EshopMenuTitle>E-shop</EshopMenuTitle>
          <LinkWrapper>
            <MenuLinkShop
              to='/e-shop'
              state={{ collection: 'Nos cafés' }}
              onClick={() => setIsOpen(!isOpen)}
              replace
            >
              - Nos cafés
            </MenuLinkShop>
            <MenuLinkShop
              to='/e-shop/'
              state={{ collection: 'S\'équiper Jh' }}
              onClick={() => setIsOpen(!isOpen)}
              replace
            >
              - S'équiper Jh
            </MenuLinkShop>
          </LinkWrapper>
        </EshopMenu>

        <MenuLink
          onClick={() => setIsOpen(!isOpen)}
          className='menu-item'
          to='/contact'
        >
          Contact
        </MenuLink>
      </Menu>}
    </>
  )
}

export default BurgerMenu
