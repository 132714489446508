import styled from '@emotion/styled'
import { Link } from 'gatsby'

export const MenuLink = styled(Link)`
  color: #000000;
  text-decoration: none;
  font-size: 0.9em;
  margin-top: 1.1em;

  :hover {
    color: #2b5c6b;
  }

  @media (max-width: 340px) {
    font-size: 0.7em;
  }
`

export const CollectButton = styled.a`
  text-decoration: none;
  font-size: 0.8em;
  padding: 0.4em 0.8em;
  background-color: #2b5c6b;
  border-radius: 20px;
  border: 1px solid transparent;
  margin-top: 2em;
  outline: none;
  text-align: center;
  color: #eed7b8;

  @media (hover: hover) {
    :hover {
      background-color: white;
      color: #000;
      border: 1px solid #2b5c6b;
    }
  }

  @media (max-width: 340px) {
    font-size: 0.6em;
  }
`

export const EshopMenu = styled.div`

`

export const EshopMenuTitle = styled.p`
  margin-bottom: 0;
`

export const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.4em;
`

export const MenuLinkShop = styled(MenuLink)`
  font-size: 0.9em;
  margin: 0.2em 0 0.2em 0.8em;
  font-family: 'Avenir Light';
`
