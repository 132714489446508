import styled from '@emotion/styled'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { breakpoints } from '../../utils/styles'

import FacebookLogo from './../../data/icons/facebook-logo.png'
import InstagramLogo from './../../data/icons/instagram-logo.png'
import TwitterLogo from './../../data/icons/twitter-logo.png'
import FooterLeft from './../../data/images/footer-left.png'
import carIcon from '../../data/icons/footer/car-icon.png'
import phoneIcon from '../../data/icons/footer/phone-icon.png'
import shopIcon from '../../data/icons/footer/shop-icon.png'

const Footer = () => {
  const image = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "images/logo.png" }) {
        childImageSharp {
          fixed(width: 180) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <>
      <FooterWrapper>
        <FooterLeftImg src={FooterLeft} />
        <Nav1>
          <NavTitle>Plan du Site</NavTitle>
          <Separator />
          <NavLink to='/'>Nous connaitre</NavLink>
          <NavLink to='/cote-pro'>Coté Pro</NavLink>
          <NavLink to='/developpement-durable'>Developpement durable</NavLink>
          <NavLink to='/e-shop'>E-shop</NavLink>
          <NavLink to='/contact'>Contact</NavLink>
          <ButtonLink href='https://jhnco-clickncollect.fr'>Click’n’Collect</ButtonLink>
        </Nav1>
        <Nav2>
          <NavTitle>A propos de JH & Co</NavTitle>
          <Separator />
          <NavLink2 to='/politique-de-remboursement'>Politique de remboursement</NavLink2>
          <NavLink2 to='/politique-de-confidentialite'>Politique de confidentialité</NavLink2>
          <NavLink2 to='/politique-expedition'>Politique d'expédition</NavLink2>
          <NavLink2 to='/condition-utilisation'>Conditions d'utilisation</NavLink2>
          <NavLink2 to='/mentions-legales'>Mention légales</NavLink2>
          <NavLink2 to='/cgv'>CGV</NavLink2>
          <SocialWrapper>
            <SocialIcon href='https://www.instagram.com/jhandco/'>
              <img src={InstagramLogo} alt='' />
            </SocialIcon>
            <SocialIcon href='https://twitter.com/JHandCoparis'>
              <img src={TwitterLogo} alt='' />
            </SocialIcon>
            <SocialIcon href='https://m.facebook.com/jhncoparis/'>
              <img src={FacebookLogo} alt='' />
            </SocialIcon>
          </SocialWrapper>
        </Nav2>
        <LogoBox>
          <FooterLogoImg fixed={image.logo.childImageSharp.fixed} />
          <FooterTitle>-Coffee & Cookie-</FooterTitle>

          <ShopInfoBox>
            <ColContainer>
              <IconBox>
                <Icon style={{ width: '3.2em' }} src={phoneIcon} />
              </IconBox>

              <ContentBox>
                <ContentTitle>
                  Numéro de téléphone
                </ContentTitle>

                <PhoneNumber href='tel:0620142775'>
                  06. 20. 14. 27. 75
                </PhoneNumber>
              </ContentBox>
            </ColContainer>

            <WhiteSeparator />

            <ColContainer>
              <IconBox>
                <Icon style={{ width: '4.2em' }} src={shopIcon} />
              </IconBox>

              <ContentBox>
                <ContentTitle>
                  Coffee Shop (Ivry sur Seine)
                </ContentTitle>

                <ContentText>
                  1, rue Blanqui | 93200 Ivry-sur-Seine
                </ContentText>

                <ContentText>
                  Du Lundi au Vendredi de <span>8h00 à 18h00</span>
                </ContentText>

                <ContentText>
                  & le Samedi de <span>10h00 à 18h00</span>
                </ContentText>
              </ContentBox>
            </ColContainer>

            <WhiteSeparator />

            <ColContainer>
              <IconBox>
                <Icon style={{ width: '5em' }} src={carIcon} />
              </IconBox>

              <ContentBox>
                <ContentTitle>
                  Coffee Truck (La Défense)
                </ContentTitle>

                <ContentText>
                  Place de la défense | 92800 Puteaux
                </ContentText>

                <ContentText>
                  Du Lundi au Vendredi de <span>7h00 à 15h00</span>
                </ContentText>
              </ContentBox>
            </ColContainer>

          </ShopInfoBox>
        </LogoBox>
      </FooterWrapper>
    </>
  )
}

export default Footer

export const FooterWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: start;
  height: 29em;
  background-color: #12282e;
  padding: 7em 9em 0 7em;
  margin-top: 10em;

  @media (max-width: ${breakpoints.xl}px) {
    padding: 7em 3em 0 3em;
  }

  @media (max-width: ${breakpoints.m}px) {
    justify-content: center;
    flex-direction: column-reverse;
    height: auto;
    padding: 0 1em 3em 1em;
    align-items: center;
  }
`

export const FooterLeftImg = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 70%;
  max-width: 900px;
  z-index: 0;

  @media (max-width: ${breakpoints.m}px) {
    display: none;
  }
`

export const NavTitle = styled.p`
  color: #eed7b8;
  font-size: 1.3em;
`

export const Separator = styled.div`
  margin-top: 0;
  border-bottom: 1px solid #eed7b8;
  width: 5em;
  margin-bottom: 1em;
`

export const Nav1 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: fit-content;
  z-index: 10;
  @media (max-width: ${breakpoints.m}px) {
    align-items: center;
    margin-bottom: 3em;
    order: 2;
  }
`

export const NavLink = styled(Link)`
  text-decoration: none;
  color: #ffffff;
  font-size: 1.2em;
  margin-bottom: 1.5em;
`

export const Nav2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: fit-content;
  align-items: flex-start;
  z-index: 10;

  @media (max-width: ${breakpoints.m}px) {
    align-items: center;
    order: 1;
  }
`

export const NavLink2 = styled(Link)`
  text-decoration: none;
  font-family: 'Avenir Light',serif;
  font-weight: 300;
  color: #ffffff;
  margin-bottom: 1em;
`

export const LogoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 29em;
  margin-top: -4em;

  @media (max-width: ${breakpoints.m}px) {
    padding: 0 1em;
    width: 100%;
    margin-top: -3em;
    margin-bottom: 3em;
    order: 2;
  }
`

export const FooterTitle = styled.p`
  text-align: center;
  font-size: 1.1em;
  color: white;
  font-family: 'Futurist Fixed', sans-serif;
`

export const FooterLogoImg = styled(Img)``

export const SocialWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 10em;
  margin-top: 1em;
`

export const SocialIcon = styled.a`
  :hover {
    cursor: pointer;
  }
  img {
    width: 2.5em;
  }
`
export const ButtonLink = styled.a`
  background-color: #eed7b8;
  border-radius: 20px;
  color: #12282e;
  width: fit-content;
  padding: 0.3em 1em;
  text-decoration: none;

  :hover {
    cursor: pointer;
    border: 2px solid #eed7b8;
    color: #eed7b8;
    background-color: transparent;
  }
`

export const FooterLink = styled(Link)`
  font-family: 'Avenir Light', sans-serif;
  font-size: 1.2em;
  color: white;
  text-decoration: none;
  margin-right: 2em;

  @media (max-width: ${breakpoints.s}px) {
    margin: 0;
  }
`

export const ShopInfoBox = styled.div`
  margin-top: 1em;
`

export const ColContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${breakpoints.m}px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`

export const IconBox = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  width: 5em;
  margin-right: 3em;

  @media (max-width: ${breakpoints.m}px) {
    margin-bottom: 1.5em;
    align-items: center;
    justify-content: center;
    margin-right: 0;
  }
`

export const Icon = styled.img`
  width: 3.1em;
`

export const ContentBox = styled.div``

export const ContentTitle = styled.p`
  color: #eed7b8;
  font-size: .8em;
  margin: 0 0 .4em;

  @media (max-width: ${breakpoints.m}px) {
    font-size: 1.1em;
  }
`

export const PhoneNumber = styled.a`
  color: white;
  font-family: 'Avenir Black', serif;
  font-weight: bold;
  font-size: 1.8em;
  margin: 0;
  text-decoration: none;
`

export const ContentText = styled.p`
  font-family: 'Avenir Light', serif;
  margin: .1em 0;
  color: white;
  font-size: .8em;

  @media (max-width: ${breakpoints.m}px) {
    font-size: 1em;
  }
  
  span {
    font-family: "Avenir Black",serif;
  }
`

export const WhiteSeparator = styled.div`
  width: 6em;
  height: .05em;
  margin: 1em 8em 1em;
  background: white;
  opacity: .2;

  @media (max-width: ${breakpoints.m}px) {
    margin: 2em 8em 2.4em;
  }
`