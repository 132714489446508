import React, { useContext } from 'react'
import CartIcon from '~/data/icons/cart.png'
import reduce from 'lodash/reduce'
import StoreContext from '~/context/StoreContext'

const useQuantity = () => {
  const {
    store: { checkout }
  } = useContext(StoreContext)
  const items = checkout ? checkout.lineItems : []
  const total = reduce(items, (acc, item) => acc + item.quantity, 0)
  return [total !== 0, total]
}

const Cart = ({ CartLink, CartCounter }) => {
  const [hasItems, quantity] = useQuantity()

  return (
    <>
      <CartLink to='/cart'>
        {hasItems && <CartCounter>{quantity}</CartCounter>}
        <img src={CartIcon} alt='' />
      </CartLink>
    </>
  )
}

export default Cart
